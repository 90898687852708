import styled from 'styled-components';

export const ImageGradient = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 525px;
  background: linear-gradient(90deg, #1f222c 0%, rgba(31, 34, 44, 0) 100%);
  opacity: 0.6;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: none;
    background: ${(props) => props.theme.colors.grey.dark};
  }
`;

export default ImageGradient;
