import styled from 'styled-components';

export const MainWrapper = styled.div`
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  width: 859px;

  ${(props) =>
    props.hasImage &&
    `
    height: 592px;
  `};

  ${(props) =>
    !props.hasImage &&
    `
    background-color: ${props.theme.colors.primary.light};
  `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 440px;
    width: 100%;

    ${(props) =>
      props.hasImage &&
      `
    max-height: 450px;
  `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;

    ${(props) =>
      props.hasImage &&
      `
    height: auto;
  `};
  }
`;

export default MainWrapper;
