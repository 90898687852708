import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  width: 859px;
  max-width: 100%;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      height: 592px;
    `};

  ${({ $fullWidth, $hasImage }) =>
    $hasImage &&
    $fullWidth &&
    css`
      height: 632px;
    `};

  ${({ $hasImage, theme }) =>
    !$hasImage &&
    css`
      background-color: ${theme.colors.primary.light};
    `};

  .main-image {
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 440px;
    width: 100%;

    ${({ $fullWidth }) =>
      $fullWidth &&
      css`
        max-width: calc(var(--window-width, 100vw) - 36px * 2);
      `};
    ${({ $hasImage }) =>
      $hasImage &&
      css`
        max-height: 450px;
      `};
    ${({ $fullWidth, $hasImage }) =>
      $hasImage &&
      $fullWidth &&
      css`
        max-height: 348px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;

    ${({ $hasImage }) =>
      $hasImage &&
      css`
        max-height: 352px;
      `};

    ${({ $fullWidth, $hasImage }) =>
      $hasImage &&
      $fullWidth &&
      css`
        max-height: 408px;
      `};

    ${({ $showDots }) =>
      $showDots &&
      css`
        overflow: visible;

        .slick-dots {
          bottom: -35px;
        }
      `};
  }
`;

export default MainWrapper;
