import MarkdownRichText from '../../MarkdownRichText';
import styled from 'styled-components';
import { Paragraph } from '@/components/core';

const Info = ({ numbers, label, colored }) => {
  return (
    <Wrapper>
      <Numbers colored={colored}>
        <MarkdownRichText>{numbers}</MarkdownRichText>
      </Numbers>
      <Label markdownProps={{ elements: ['a'] }}>{label}</Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-left: 1px solid rgb(47, 44, 42, 0.2);
  max-width: 298px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    border: unset;
    max-width: 216px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;

const Numbers = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
  padding-left: 24px;
  color: ${(props) =>
    props.colored ? props.theme.colors.primary.default : props.theme.colors.grey.dark};

  strong {
    font-weight: 700;
    font-size: 45px;
    line-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 30px;
    padding-left: 0;

    strong {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 22px;
    line-height: 34px;

    strong {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;

const Label = styled(Paragraph)`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 0 0 24px;
  opacity: 0.8;

  sup {
    color: ${(props) => props.theme.colors.primary.default};
    font-size: 80%;
    vertical-align: top;
    line-height: 1;
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 4px;
  }
`;

export default Info;
