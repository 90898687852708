import styled from 'styled-components';

import { Col } from '@/components/core/flexbox';

export const CustomRow = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    width: 100%;
  }
`;

export default CustomRow;
