import styled, { css } from 'styled-components';

const ArrowButton = styled.button`
  width: 48px;
  height: 48px;
  cursor: pointer;
  border: 1px solid #d8dbe1;
  border-radius: 50%;
  transition: all 0.3s ease;

  ${(props) =>
    props.$flip &&
    css`
      transform: scaleX(-1);
      margin-left: 12px;
    `};

  &:hover {
    border-color: ${(props) => props.theme.colors.grey.default};
  }
`;

export default ArrowButton;
