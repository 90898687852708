import styled from 'styled-components';

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 83px;
  gap: 56px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: 40px;
    flex: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 32px;
    margin-left: 0;
    gap: 24px;
  }
`;

export default InfoWrapper;
