import { Paragraph } from '@/components/core';
import MarkdownRichText from '../MarkdownRichText';
import styled from 'styled-components';

const Info = ({ numbers, label, colored }) => {
  return (
    <Wrapper>
      <Numbers colored={colored}>
        <MarkdownRichText>{numbers}</MarkdownRichText>
      </Numbers>
      <Label markdownProps={{ elements: ['a'] }} data-testid="numbers-label" size="lg">
        {label}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-left: 1px solid rgb(47, 44, 42, 0.2);
  max-width: 298px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    border: unset;
    max-width: 216px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
  }
`;

const Numbers = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 30px;
  font-weight: 300;
  line-height: 40px;
  padding-left: 24px;

  strong {
    font-weight: 700;
    font-size: 45px;
    line-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 30px;
    padding-left: 0;

    strong {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 22px;
    line-height: 34px;

    strong {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;

const Label = styled(Paragraph)`
  font-family: ${(props) => props.theme.typography.family.title};
  padding: 8px 0 0 24px;
  opacity: 0.8;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 4px;
  }
`;

export default Info;
