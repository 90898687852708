import Image from 'next/image';
import styled, { css } from 'styled-components';

import ArrowButton from './ArrowButton';

const SliderArrows = ({ sliderRef, top }) => {
  return (
    <Wrapper $top={top}>
      <ArrowButton onClick={() => sliderRef.current.slickPrev()}>
        <Image
          src="/assets/member-impact-slider/arrow.svg"
          alt="Left Arrow"
          width={48}
          height={48}
        />
      </ArrowButton>

      <ArrowButton onClick={() => sliderRef.current.slickNext()} $flip>
        <Image
          src="/assets/member-impact-slider/arrow.svg"
          alt="Right Arrow"
          width={48}
          height={48}
        />
      </ArrowButton>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  text-align: right;
  height: 48px;

  ${({ $top }) =>
    $top
      ? css`
          margin-bottom: 17px;
          margin-top: 0;
        `
      : css`
          margin-bottom: 0;
          margin-top: 16px;
        `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

export default SliderArrows;
