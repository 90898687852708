import styled from 'styled-components';

export const OuterWrapper = styled.div`
  background-color: ${(props) =>
    props.$whiteBackground ? props.theme.colors.white : props.theme.colors.neutral.default};
  padding-top: 136px;
  padding-bottom: ${(props) => (props.$withPaddingBottom ? '136px' : '0')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 104px;
    padding-bottom: ${(props) => (props.$withPaddingBottom ? '104px' : '0')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 64px;
    padding-bottom: ${(props) => (props.$withPaddingBottom ? '64px' : '0')};
  }
`;

export default OuterWrapper;
