import styled from 'styled-components';

import QuotesSVG from '@/public/assets/icons/quotes.svg';
import Image from 'next/image';

const QuoteInfo = ({ quote, name, clinicalSituation }) => {
  return (
    <Wrapper>
      <QuotesImage />

      <Quote>{quote}</Quote>

      <ClientDetailsWrapper>
        <AuthorWrapper>
          <Name>{name}</Name>
          <Label>{clinicalSituation}</Label>
        </AuthorWrapper>
        <ClientLogoWrapper>
          <Image
            src="/assets/employer/dominos.png?v=2"
            alt="Domino´s logo"
            width={97}
            height={76}
          />
        </ClientLogoWrapper>
      </ClientDetailsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.blockquote`
  padding: 64px 56px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 48px 40px;
  }
`;

const QuotesImage = styled(QuotesSVG)`
  fill: currentColor;
  margin-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 18px;
    margin-bottom: 24px;
  }
`;

const Quote = styled.p`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  max-width: 600px;
  padding-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 18px;
    line-height: 24px;
    max-width: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 16px;
    line-height: 22px;
    max-width: unset;
  }
`;

const Name = styled.div`
  font-family: ${(props) => props.theme.typography.family.body};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.9;
  padding-bottom: 4px;
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  opacity: 0.9;
`;

const AuthorWrapper = styled.div``;

const ClientDetailsWrapper = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: block;
  }
`;

const ClientLogoWrapper = styled.div`
  img {
    position: absolute;
    right: 56px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      right: 48px;
      width: 76px;
      height: 56px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      position: unset;
      right: unset;
      margin-top: 16px;
    }
  }
`;

export default QuoteInfo;
