import { Container } from '@/components/core/flexbox';

import CustomCol from './CustomCol';
import CustomRow from './CustomRow';
import Info from './Info';
import InfoWrapper from './InfoWrapper';
import MainWrapper from './MainWrapper';
import OuterWrapper from './OuterWrapper';
import QuoteInfo from './QuoteInfo';
import { Section } from '@/components/core';
import Intro from '../Intro';

const SplitQuoteWithMetrics = ({ content, hasImage = false }) => {
  const { label, title, subTitle, linkHref, linkLabel, metrics, quote, quoteName, quoteLabel } =
    content;

  return (
    <Section>
      <OuterWrapper $whiteBackground>
        <Intro
          upperLabel={label}
          title={title}
          description={subTitle}
          button={{
            label: linkLabel,
            href: linkHref,
            type: 'text',
          }}
          align="left"
          as="header"
          innerSpacer
        />
        <Container>
          <CustomRow>
            <CustomCol lg={12}>
              <MainWrapper>
                <QuoteInfo
                  quote={quote}
                  name={quoteName}
                  clinicalSituation={quoteLabel}
                  colored={!hasImage}
                />
              </MainWrapper>

              <InfoWrapper>
                {metrics?.map((customer, index) => (
                  <Info key={index} numbers={customer.numbers} label={customer.label} colored />
                ))}
              </InfoWrapper>
            </CustomCol>
          </CustomRow>
        </Container>
      </OuterWrapper>
    </Section>
  );
};

export default SplitQuoteWithMetrics;
